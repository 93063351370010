import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
const TopCentersIncidents = ({data}) => {
  const [state, setState] = React.useState(null);
  useEffect(() => {
    setState({
      series: [{
        name: 'Incidents',
        data: data?.data
      }],
      options: {
        chart: {
          // height:150,
          type: 'bar',
          background: 'transparent', // Remove the background
          toolbar: {
            show: true, // Enable or disable the toolbar
            tools: {
              download: false, // Disable the download option
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
          
        },
        plotOptions: {
          bar: {
            columnWidth: '30%', // Set bar width as a percentage of the available space (adjust this value)
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        
        xaxis: {
          categories: data?.categories,
          // position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
      //   grid: {
      //     show: false, // Disable the grid lines
      //   },
        fill: {
          opacity: 1, // Ensure bars are filled with color without any transparency
        },
        colors: ['#ED1D24'],
      },
    })
  }, [data]);
  return (
    <>
      {state && <ReactApexChart options={state.options} series={state.series} type="bar" height={200} />}
      
    </>
  );
};
export default TopCentersIncidents;