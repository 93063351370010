import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import StatusCapsual from "../../components/DataTable/StatusCapsule";
import DateBox from "../../components/General/DateBox";
import ExamDetails from "./SuperAdmin/ExamDetails";
import ExamCounter from "./SuperAdmin/ExamCounter";
import AttendanceProgress from "./SuperAdmin/AttendanceProgress";
import ReportingProgress from "./SuperAdmin/ReportingProgress";
import RRBContacts from "./SuperAdmin/RRBContacts";
import RRBExamSchedule from "./SuperAdmin/RRBExamSchedule";
import { FormFieldSelect } from "../../components/Form/FormField";
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAll } from "../../api/MinistryExams";
import { getDashboardDetails, getDashboardDetailsById, getDashboardDetailsByMinistryId } from "../../api/DashboardAPI";
import moment from "moment";
import NoDataFound from "../../components/General/NoDataFound";
import useIsMobile from "../../hooks/useIsMobile";
import Loader from "../../components/Loader";
import IncidentAnalytics from "./SuperAdmin/IncidentAnalytics";


const SuperAdminDashboard = ({ data={},setDashboard }) => {
  const isMobile = useIsMobile();
  const [exams, setExams] = useState([]);
  const [examDays, setExamDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultFilterValue = {
      exam: "",
    };
    const {
      control,
      formState: { errors: dashboardFilterError },
      watch,
      setValue,
      getValues
    } = useForm({
      mode: "onChange",
      defaultValues: defaultFilterValue,
    });
  const [filter, setFilter] = useState({
    _page: 1,
    _limit: 1000,
    q: "",
    _sort: "id",
    _order: "desc",
    // status:"1"
  });
  // Function to get the exam days with the maximum number of days
  const getExamDaysWithMaxDays =  (data) => {
    let maxDaysExam = null;
    let maxDaysCount = 0;
    console.log("data",data);
    if(data){
      
      data.Exams.forEach(exam => {
          const examDaysCount = exam.examDays.length;
  
          if (examDaysCount > maxDaysCount) {
            maxDaysCount = examDaysCount;
            maxDaysExam = exam;
          }
        });
      if (maxDaysExam && maxDaysExam.examDays) {
        // Sort examDays by date in ascending order
        maxDaysExam.examDays.sort((a, b) => new Date(a.date) - new Date(b.date));
        console.log("maxDaysExam.examDays (sorted)", maxDaysExam.examDays);
      }
      console.log("maxDaysExam.examDays",maxDaysExam.examDays);
      return maxDaysExam ? maxDaysExam.examDays : [];
    }
  }
  const fetchAllMinistryExams = async ()=>{
    const exams = await getAll(filter);
    setExams(exams.data.records);
  };
  useEffect(() => {
      fetchAllMinistryExams();
  },[]);
  const fetchDashboardData = useCallback(async (ministry_id,examDayId=null) => {
    setIsLoading(true);
    try {
      if(ministry_id){
        
        const res =  await getDashboardDetailsByMinistryId(ministry_id,examDayId)
        setDashboard(res.data);
      }
    } catch (error) {
      console.log("🚀 ~ fetchDashboardData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  const selectedExam = watch("exam");
  const selectedExamDay = watch("examDay");
  
  useEffect(()=>{
    setValue('examDay',[]);
    if(selectedExam){
      const examDays = getExamDaysWithMaxDays(exams.find((exam) => exam.id === selectedExam.value));
      setExamDays(examDays);
      fetchDashboardData(selectedExam.value)
    }
    
  },[selectedExam])
  useEffect(()=>{
    if(selectedExamDay){
      console.log("selectedExamDay",selectedExamDay.value);
      fetchDashboardData(selectedExam.value,selectedExamDay.value)
    }
  },[selectedExamDay])
  return (
    <>
      <Col md="12">
        <Row className="d-flex align-items-center mb-2">
          <Col md="8">
            <h2 className="page-title">Exams Stats</h2>
          </Col>
          <Col md="2">
            <FormFieldSelect
              name="exam"
              control={control}
              options={exams.map((exam) => ({
                label: exam.exam_name,
                value: exam.id,
              }))}
              error={dashboardFilterError?.role}
              size="12"
              placeholder="Select Exam"
            />
          </Col>
          <Col md="2">
          <FormFieldSelect
            name="examDay"
            control={control}
            options={[
              { label: "All Exam Days", value: null },
              ...examDays.map((day) => ({
                label: moment(day.date).format('DD/MM'),
                value: day.date,
              }))
            ]}
            error={dashboardFilterError?.role}
            size="12"
            placeholder="All Exam Days"
            disabled={!getValues("exam")}
          />
          </Col>
        </Row>
        {
        data?.onGoingExamsStats?.exam?.post?.name ?
        <Card>
          <Card.Body className="p-3">
            <ExamDetails data={data?.onGoingExamsStats} />
          </Card.Body>
          <Card.Body className="p-3">
            <ExamCounter data={data?.onGoingExamsStats} className="mb-3" />
            <IncidentAnalytics data={data?.onGoingExamsStats} className="mb-3" />
            <AttendanceProgress data={data?.onGoingExamsStats} className="mb-3" />
            <ReportingProgress data={data?.onGoingExamsStats} className="mb-3" />
            <RRBExamSchedule data={data?.onGoingExamsStats?.dateWiseRrrbCenters} className="mb-3"/>
            <RRBContacts data={data?.onGoingExamsStats} />
          </Card.Body>
        </Card>
        :  <NoDataFound message="No active exams currently. Please select exam/exam day filters to see past exam data." minHeight={isMobile ? "80vh" : "50vh"}/>}
      </Col>
      {isLoading && <Loader />}
    </>
  );
};

export default SuperAdminDashboard;
