import React from "react";
import { Row } from "react-bootstrap";
import { StatCard } from "../StatCard";
import { RIconRRB } from "../../../components/Icons/RIconRRB";
import { RIconPC, RIconPerson } from "../../../components/Icons";

const ExamCounter = ({ data = {}, className = "" }) => {
  return (
    <Row className={`g-3 ${className}`}>
      <StatCard
        icon={<RIconRRB />}
        bgClass="bg-cyan"
        title={data?.rrbs?.length || 0}
        subtitle="Railway Recruitment Boards"
        colSize="col-6 col-md-4 col-xxl"
        isCardBg
      />
      <StatCard
        icon={<RIconPC />}
        bgClass="bg-warning"
        title={data?.centers}
        subtitle="Exams Centers"
        colSize="col-6 col-md-4 col-xxl"
        isCardBg
      />
      <StatCard
        icon={<RIconPerson />}
        bgClass="bg-yellow"
        title={data?.centerCoordinators}
        subtitle="Center Coordinators"
        colSize="col-6 col-md-4 col-xxl"
        isCardBg
      />
    </Row>
  );
};

export default ExamCounter;
