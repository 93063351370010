import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const RadialBarChart = ({ title, data = {}, height = "240" }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartKey, setChartKey] = useState(0); // Unique key for reinitialization

  // Update chart options and series when props change
  useEffect(() => {
    if (data === "") return; // Avoid updates with empty data

    // Update chart options
    setChartOptions({
      chart: {
        type: "radialBar",
        offsetY: -10,
        toolbar: { show: false },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "16px",
              fontWeight: "400",
              color: "var(--tblr-dark)",
              offsetY: 120,
            },
            value: {
              offsetY: 76,
              fontSize: "22px",
              fontWeight: "600",
              color: "var(--tblr-success)",
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["var(--tblr-success)"],
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
        colors: ["var(--tblr-success)"],
      },
      stroke: {
        dashArray: 4,
      },
      labels: [title],
    });

    // Update chart series
    setChartSeries([data]);

    // Force chart reinitialization
    setChartKey((prevKey) => prevKey + 1);
  }, [title, data]);

  // Fallback for invalid data
  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <Chart
      key={chartKey} // Force reinitialization on data change
      options={chartOptions}
      series={chartSeries}
      type="radialBar"
      height={height}
    />
  );
};

export default RadialBarChart;