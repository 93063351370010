import React, { useEffect, useState } from 'react';
import { use } from 'react';
import ReactApexChart from 'react-apexcharts';
const CategoryWiseIncidents = ({data}) => {
    const [state, setState] = useState(null);
    const [chartKey, setChartKey] = useState(0); // Unique key for reinitializing the chart
    
    //   useEffect(() => {
    //     
    //   }, [state.series]); // Run effect when series values change
    useEffect(() => {
        // Update the series and options with the new data
        setState({
            series: data?.data, // Your data values
            options: {
              chart: {
                type: 'donut',
              },
              legend: {
                show: false, // Turn off the legend
              },
              
              labels: data?.categories, // Set category names as labels
              dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                  // You can format the label to show the actual value along with the percentage if needed
                  const value = opts.w.config.series[opts.seriesIndex]; // Get the actual value
                  return value; // Display the actual value
                },
                
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: '55%', // Adjust the donut size if needed
                  },
                },
              },
              colors: [], // Dynamic colors will be set here
            },
        });
        if(state){
            // Calculate opacity based on series values
            const maxValue = Math.max(...state.series);
            
            const colors = state.series.map(value => {
            const baseColor = '#F44336'; // Default base color for others
            if (value === maxValue) {
                return '#ED1D24'; // Highest value gets the color red
            }
        
            // Apply opacity to other colors based on the series value
            const opacity = 0.2 + (value / maxValue) * 0.8; // Opacity between 0.2 and 1
            return `${baseColor}${Math.round(opacity * 255).toString(16).padStart(2, '0')}`; // Add opacity to color hex
            });
        
            // Update the options with the dynamically calculated colors
            setState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                colors: colors,
            },
            }));
            setChartKey((prevKey) => prevKey + 1);
        }
        
    },[data]);
  return (
    <>
      {
        state && <ReactApexChart key={chartKey} options={state.options} series={state.series} type="donut" height={200} />
      }
      
    </>
  );
};
export default CategoryWiseIncidents;