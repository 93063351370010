import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const getDashboardDetails = async () => {
  return axios
    .get(`${API_PREFIX}/dashboard`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error?.response.data));
};

export const getDashboardDetailsById = async (id) => {
  return axios
    .get(`${API_PREFIX}/dashboard?id=${id}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error?.response.data));
};

export const getDashboardDetailsByMinistryId = async (
  id,
  examDayId,
  rrb_id = null
) => {
  return axios
    .get(
      `${API_PREFIX}/dashboard/super-admin-dashboard?ministry_exam_id=${id}&exam_day_id=${examDayId}&id=${rrb_id}`
    )
    .then((res) => res.data)
    .catch((error) => Promise.reject(error?.response.data));
};
