import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import StatusCapsual from "../../../components/DataTable/StatusCapsule";
import DateBox from "../../../components/General/DateBox";
import moment from "moment";

const ExamDetails = ({ data = {} }) => {
  const { exam = {}, examDates = [] } = data || {};
  return (
    <Row className="g-3 align-items-center">
      <Col>
        <h3 className="mb-1 fs-18 fw-medium">{exam?.exam_name}</h3>
        <Card.Text className="text-secondary mb-2">
          {exam?.post?.description} ({exam?.post?.name})
        </Card.Text>
        <StatusCapsual status={exam?.status || "In Progress"} />
      </Col>
      <Col md="auto">
        <Row className="g-2">
          {examDates?.map((exmDay, dayInd) => {
            const isToday = moment(exmDay).isSame(moment(), "day");
            return(
            <Col key={dayInd} xs="auto">
              <DateBox date={exmDay} className={`${isToday?'bg-success-lt':''}`} />
            </Col>
          )})}
        </Row>
      </Col>
    </Row>
  );
};

export default ExamDetails;
