export const ROLES = {
  SUPERADMIN: "super-admin",
  CENTER_COORDINATOR: "center-coordinator",
  CONTROL_ADMIN: "control-admin"
};
export const PAGE_LENGTH = [10, 25, 50, 100];
export const DEFAULT_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you really want to delete this record? This operation cannot be undone later.",
  actionBtnText: "Delete",
  action: "DELETE",
};
export const DEFAULT_CRED_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you wish to send login details (username/password) to ALL the center coordinators for this exam date?",
  // description:
  // "Do you wish to send login details (username/password) to all the center coordinators for this exam?",
  actionBtnText: "SEND",
  action: "SEND",
};
export const DEFAULT_USER_CRED_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: "Are you sure?",
  description:
    "Do you wish to send login details (username/password) to this user",
  // description:
  // "Do you wish to send login details (username/password) to all the center coordinators for this exam?",
  actionBtnText: "SEND",
  action: "SEND",
};
export const DEFAULT_CONFIRM_OFFCANVAS = {
  show: false,
  isLoading: false,
  title: "Confirmation",
  description:
    "Do you really want to delete this record? This operation cannot be undone later.",
  actionBtnText: "Submit",
  action: "submit",
};
export const CITIES = [
  { label: "ADI", value: "ahemdabad" },
  { label: "GNC", value: "gandhinagar" },
  { label: "MSH", value: "mehsana" },
  { label: "BRC", value: "gotri" },
  { label: "RJT", value: "rajkot" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Amravati", value: "Amravati" },
  { label: "Dhule", value: "Dhule" },
  { label: "Jalgaon", value: "Jalgaon" },
  { label: "Nashik", value: "Nashik" },
  { label: "Surat", value: "Surat" },
  { label: "Chhatrapati Sambhaji Nagar", value: "Chhatrapati Sambhaji Nagar" },
  { label: "Nanded", value: "Nanded" },
  { label: "Kolhapur", value: "Kolhapur" },
  { label: "Pune", value: "Pune" },
  { label: "Kalaburagi(Gulbarga)", value: "Kalaburagi" },
  { label: "Latur", value: "Latur" },
  { label: "BPL", value: "Bhopal" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Mangalore", value: "Mangalore" },
  { label: "Hubli", value: "Hubli" },
  { label: "Kalaburagi", value: "Kalaburagi" },
  { label: "Chikmagalur", value: "Chikmagalur" },
  { label: "Mysuru", value: "Mysuru" },
  { label: "Belgaum", value: "Belgaum" },
  { label: "Bidar", value: "Bidar" },
  { label: "Bijapur", value: "Bijapur" },
  { label: "Hampi", value: "Hampi" },
  { label: "Gokarna", value: "Gokarna" },
  { label: "Hassan", value: "Hassan" },
  { label: "Murudeshwara", value: "Murudeshwara" },
  { label: "Shimoga", value: "Shimoga" },
  { label: "Udupi", value: "Udupi" },
  { label: "Panaji", value: "Panaji" },
  { label: "Ambala", value: "Ambala" },
  { label: "Mohali", value: "Mohali" },
  { label: "Patiala", value: "Patiala" },
  { label: "Bathinda", value: "Bathinda" },
  { label: "Baddi", value: "Baddi" },
  { label: "Bilaspur", value: "Bilaspur" },
  { label: "Shimla", value: "Shimla" },
  { label: "Una", value: "Una" },
  { label: "Kolkata", value: "Kolkata" },
  { label: "Kalyani", value: "Kalyani" },
  { label: "Balasore", value: "Balasore" },
  { label: "Nilgiri", value: "Nilgiri" },
  { label: "Bokaro", value: "Bokaro" },
  { label: "Dhanbad", value: "Dhanbad" },
  { label: "Hazaribag", value: "Hazaribag" },
  { label: "Daltonganj", value: "Daltonganj" },
  { label: "Ranchi", value: "Ranchi" },
  { label: "Jamshedpur", value: "Jamshedpur" },
  { label: "Rourkela", value: "Rourkela" },
  { label: "Jabalpur", value: "Jabalpur" },
  { label: "Sagar", value: "Sagar" },
  { label: "Satna", value: "Satna" },
  { label: "Indore", value: "Indore" },
  { label: "Ujjain", value: "Ujjain" },
  { label: "Buldana", value: "Buldana" },
  { label: "Yavatmal", value: "Yavatmal" },
  { label: "Hamirpur", value: "Hamirpur" },
  { label: "Mandi", value: "Mandi" },
  { label: "Kangra", value: "Kangra" },
  { label: "Pathankot", value: "Pathankot" },
  { label: "Jammu", value: "Jammu" },
  { label: "Samba", value: "Samba" },
  { label: "Srinagar", value: "Srinagar" },
  { label: "Amritsar", value: "Amritsar" },
  { label: "Jalandhar", value: "Jalandhar" },
  { label: "Ludhiana", value: "Ludhiana" },
  { label: "Phagwara", value: "Phagwara" },
  { label: "Akola", value: "Akola" },
  { label: "Raipur", value: "Raipur" },
  { label: "Nagpur", value: "Nagpur" },
];
export const ROLE_LIST = [
  { label: "Super Admin", value: "super-admin" },
  //   { label: "Region Coordinator", value: "region-coordinator" },
  { label: "Center Coordinator", value: "center-coordinator" },
];
export const EXAM_STATUS = [{ label: "Upcoming", value: "upcoming" }];
export const EXAM_DURATION = [
  { label: "30", value: 30 },
  { label: "45", value: 45 },
  { label: "60", value: 60 },
  { label: "75", value: 75 },
  { label: "90", value: 90 },
];
