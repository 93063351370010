import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import Loader from "../components/Loader";
import InjectProtectedNode from "./injectProtectedNode";

import {
  addAuthData,
  resetAuthData,
} from "../store/slices/authUser/authUserSlice";
import "react-toastify/dist/ReactToastify.css";
import UnauthorizedNode from "./UnauthorizedNode";
import { ensureSocketConnected } from "../socket";

const Layout = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registered, setRegistered] = useState(false);

  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  if (!registered && authSelector.id) {
    const registerAdmin = async () => {
      const socket = await ensureSocketConnected();
      socket.emit("register-admin", authSelector.id);
      console.log(
        "Socket: ",
        socket.id,
        " registered the admin: ",
        authSelector.id
      );
    };
    registerAdmin();
    setRegistered(true);
  }

  let isRefreshing = false; // Prevent multiple refresh requests
  let refreshSubscribers = []; // Queue of requests waiting for a refreshed token

  // Notify all subscribers with the new token
  const onRefreshed = (newToken) => {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = [];
  };

  // Add a failed request to the queue
  const addSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  // Axios Request Interceptor
  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_PREFIX;
      if (authSelector?.tokens?.accessToken) {
        req.headers = {
          "Content-Type": "application/json",
          type: "web",
          Authorization: `Bearer ${authSelector.tokens?.accessToken}`,
          ...req.headers,
        };
      }
      return req;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Axios Response Interceptor
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        error?.response?.data?.message !== "Invalid credentials"
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            // Attempt to refresh the token
            const refreshResponse = await axios.post("/auth/refresh-token", {
              refreshToken: authSelector.tokens.refreshToken,
            });

            const { accessToken, refreshToken } = refreshResponse.data.data;

            // Update Redux state with new tokens
            dispatch(
              addAuthData({
                ...authSelector,
                tokens: { accessToken, refreshToken },
              })
            );

            // Notify all queued requests with the new token
            onRefreshed(accessToken);
            isRefreshing = false;

            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            // Handle refresh token failure
            isRefreshing = false;
            refreshSubscribers = [];
            toast.error("Session expired. Please log in again.");
            dispatch(resetAuthData());
            navigate("/login");
            return Promise.reject(refreshError);
          }
        }

        // Queue the failed request until the token is refreshed
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    }
  );

  return (
    <Fragment>
      {authSelector.auth ? (
        <InjectProtectedNode authSelector={authSelector} />
      ) : (
        <Suspense fallback={<Loader />}>
          <UnauthorizedNode />
        </Suspense>
      )}

      <ToastContainer
        pauseOnFocusLoss
        draggable={false}
        hideProgressBar={true}
        theme="colored"
      />
    </Fragment>
  );
});

export default Layout;
