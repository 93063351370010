import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ReportingStackBar = ({ title, data, height = "240" }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [chartKey, setChartKey] = useState(0); // Unique key for reinitializing the chart

  useEffect(() => {
    if (
      !data ||
      !data.categories ||
      !data.centers ||
      !data.saved ||
      !data.submitted
    ) {
      return; // Avoid updates with invalid data
    }

    // Update chart options
    setChartOptions({
      chart: {
        type: "bar",
        stacked: true,
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        // custom: function({ series, seriesIndex, dataPointIndex, w }) {
        //   let tooltipContent = `<div style="padding: 5px 10px;">`;
        //   series.forEach((value, idx) => {
        //     const seriesName = w.globals.seriesNames[idx];
        //     const color = w.globals.colors[idx];
        //     tooltipContent += `
        //       <div style="display: flex; align-items: center; margin-bottom: 4px;">
        //         <span style="display: inline-block; width: 10px; height: 10px; background: ${color}; margin-right: 6px; border-radius: 50%;"></span>
        //         <span>${seriesName}: ${value[dataPointIndex]}</span>
        //       </div>
        //     `;
        //   });
        //   tooltipContent += `</div>`;
        //   return tooltipContent;
        // },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: data.categories || [],
      },
      legend: {
        show: false,
      },
    });

    // Update chart series
    setChartSeries([
      {
        name: "Pending",
        data: data.centers || [],
        color: "#A5A5A5",
      },
      {
        name: "Started",
        data: data.saved || [],
        color: "var(--tblr-warning)",
      },
      {
        name: "Submitted",
        data: data.submitted || [],
        color: "var(--tblr-success)",
      },
    ]);

    // Force chart reinitialization
    setChartKey((prevKey) => prevKey + 1);
  }, [data]);

  // Fallback for invalid data
  if (
    !data ||
    !data.categories ||
    !data.centers ||
    !data.saved ||
    !data.submitted
  ) {
    return <div>No data available</div>;
  }

  return (
    <Chart
      key={chartKey} // Force reinitialization on data change
      options={chartOptions}
      series={chartSeries}
      type="bar"
      height={height}
    />
  );
};

export default ReportingStackBar;
