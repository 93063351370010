import axios from "axios";
const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const getAll = async (param, extra = "") => {
  let filter =
    "?" +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== "")
          .map(function (key) {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(param[key])
            );
          })
          .join("&")
      : "");
  return await axios
    .get(`${API_PREFIX}/ministry-exams${filter}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getOne = async (id) => {
  return await axios
    .get(`${API_PREFIX}/ministry-exams/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const create = async (payload) => {
  return await axios
    .post(`${API_PREFIX}/ministry-exams`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const update = async (id, payload) => {
  return await axios
    .put(`${API_PREFIX}/ministry-exams/${id}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const remove = async (id) => {
  return await axios
    .delete(`${API_PREFIX}/ministry-exams/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
