import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Layout from "./layout";
import "./assets/css/styles.css";
import { initializeSocket } from "./socket";

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

initializeSocket();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
