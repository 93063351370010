import { IconClipboardText, IconMap2, IconMenu2, IconPasswordUser, IconPoint, IconReportSearch, IconUsers, IconX } from "@tabler/icons-react";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getEntityCount } from "../api/CommonApi";
import logo from "../assets/images/logo.svg";
import { ROLES } from "../common/constants";
import useIsMobile from "../hooks/useIsMobile";
import { addsidebarCounter } from "../store/slices/sideBar/sidebarCounterSlice";
import HeaderDropdown from "./HeaderDropdown";

import { CentersDB, Exams, ReportsSidebar, RIconDashboardFill, RIconExamFill, RIconIncidentFill, RIconPerson, RIconSetupFill, RIUserFill } from "./Icons";

import { toggleSidebar } from "../store/slices/sideBar/sidebarSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  const isSidebarVisible = useSelector((state) => state.rrb.sidebarReducer.isVisible);
  const isMobile = useIsMobile();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState({
    show: false,
    place: "",
  });

  const fetchEntityCount = async () => {
    const { data } = await getEntityCount();
    const { centerCount, divisionCount, postCount, rrbCount, userCount, zonesCount } = data;
    dispatch(
      addsidebarCounter({
        rrbs: rrbCount,
        posts: postCount,
        users: userCount,
        divisions: divisionCount,
        centers: centerCount,
        exams: 0,
        zones: zonesCount
      })
    );
  };

  const sidebarCounterSelector = useSelector((state) => state.rrb.sidebarCounterReducer);
  useEffect(() => {
    fetchEntityCount();
  }, [sidebarCounterSelector.shouldUpdate]);
  const handleMenuItemClick = () => {
    setIsExpanded(false); // Collapse the menu when a menu item is clicked
  };
  const handleToggle = (isOpen, item) => {
    setIsOpen((prevState) => ({
      show: isOpen ? item : false,
      place: item,
    }));
  };

  const handleSelect = (item) => {
    setIsOpen({
      show: true,
      place: item,
    });
  };

  // Define menus for different roles
  const superAdminMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    {
      name: "RRB Dashboards",
      to: "/rrb-dashboards",
      icon: <RIconDashboardFill />,
    },
    {
      name: "Setup",
      icon: <RIconSetupFill />,
      submenu: [
        {
          name: "Recruitment Boards",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/recruitment_boards",
          count: sidebarCounterSelector.rrbs,
        },
        {
          name: "Zones",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/zones",
          count: sidebarCounterSelector.zones,
        },
        {
          name: "Users",
          to: "/users",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.users,
        },
        {
          name: "Posts",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/posts",
          count: sidebarCounterSelector.posts,
        },
      ],
    },
  ];
  const ControlAdminMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    {
      name: "Users",
      to: "/control_users",
      icon: <RIUserFill />,
    },
    {
      name: "Centers",
      to: "/control_centers",
      icon: <CentersDB />,
    },
    {
      name: "Exams",
      to: "/control_exams",
      icon: <Exams />,
    },
    {
      name: "Reports",
      to: "/control_reports",
      icon: <ReportsSidebar />,
    },
    {
      name: "Login Activity",
      to: "/control_loginactivity",
      icon: <IconPasswordUser />,
    },
  ];

  const adminMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    {
      name: "Exams",
      to: "/exams",
      icon: <RIconExamFill />,
      count: sidebarCounterSelector.exams,
    },
    {
      name: "Reports",
      icon: <ReportsSidebar />,
      submenu: [
        {
          name: "Exam Reports",
          to: "/control_reports",
          icon: <IconPoint size={10} stroke={8} />,
        },
      ]
    },
    {
      name: "Incidents",
      to: "/incidents",
      icon: <RIconIncidentFill />,
    },
    {
      name: "Setup",
      icon: <RIconSetupFill />,
      submenu: [
        {
          name: "Divisions",
          to: "/divisions",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.divisions,
        },
        {
          name: "Centers",
          icon: <IconPoint size={10} stroke={8} />,
          to: "/centers",
          count: sidebarCounterSelector.centers,
        },
        {
          name: "Users",
          to: "/users",
          icon: <IconPoint size={10} stroke={8} />,
          count: sidebarCounterSelector.users,
        },
      ],
    },
  ];

  const regionCoordinatorMenuItems = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: <RIconDashboardFill />,
    },
    // {
    //   name: "Centers",
    //   to: "/centers",
    //   icon: <IconBuilding size={20} />,
    // },
  ];

  const centerCoordinatorMenuItems = [
    {
      name: "Exams",
      to: "/exams",
      icon: <RIconExamFill />,
    },
    // {
    //   name: "Completed Exams",
    //   to: "/completed-exams",
    //   icon: <IconBook size={20} />,
    // },
    // {
    //   name: "Reports",
    //   to: "/reports",
    //   icon: <IconBook size={20} />,
    // },
  ];

  const getMenuItems = () => {
    switch (authSelector?.role.slug) {
      case "super-admin":
        return superAdminMenuItems;
      case "director-general":
        return superAdminMenuItems;
      case "additional-member-staff":
        return superAdminMenuItems;
      case "executive-director-establishment":
        return superAdminMenuItems;
      case "establishment-director-i":
        return superAdminMenuItems;
      case "establishment-director-ii":
        return superAdminMenuItems;
      case "deputy-director":
        return superAdminMenuItems;
      case "admin":
        return adminMenuItems;
      case "member-secretary":
        return adminMenuItems;
      case "deputy-secretary":
        return adminMenuItems;
      case "assistant-secretary":
        return adminMenuItems;
      case "region-coordinator":
        return regionCoordinatorMenuItems;
      case "additional-region-coordinator":
        return regionCoordinatorMenuItems;
      case "center-coordinator":
        return centerCoordinatorMenuItems;
      case "control-admin":
        return ControlAdminMenuItems;
      default:
        return [];
    }
  };
  useEffect(() => {
    const submenuPaths = ["/divisions", "/centers", "/users"]; // Define submenu paths
    const currentPath = location.pathname; // Access current path from useLocation

    // Check if the current path matches any submenu path
    if (submenuPaths.includes(currentPath)) {
      setIsOpen({
        show: true,
        place: "Setup",
      });
    } else {
      setIsOpen({
        show: false,
        place: "",
      });
    }
  }, [location.pathname]);
  const SubMenu = ({ item }) => {
    const isCurrentOpen = isOpen.place === item.name && isOpen.show;

    return (
      <NavDropdown
        title={
          <Fragment>
            <span 
            className="nav-link-icon d-md-none d-lg-inline-block"
            >{item.icon}</span>
            <span 
            className="nav-link-title"
            >{item.name}</span>
          </Fragment>
        }
        id={`topnav-dropdown-${item.name}`}
        // className={`${isCurrentOpen ? "drop-down-item-select-menu d-block" : ""}`}
        show={isCurrentOpen}
        onToggle={(show) => handleToggle(show, item.name)}
        onSelect={() => handleSelect(item.name)}
      >
        <div 
        className="dropdown-menu-columns"
        >
          <div 
          className="dropdown-menu-column"
          >
            {item.submenu.map((subItem) => (
              <NavDropdown.Item key={`submenu-${subItem.name}`} as={NavLink} to={subItem.to}>
                {!isMobile && <span 
                className="nav-link-icon"
                >{subItem.icon}</span>}
                <span 
                className="nav-link-title"
                >
                  {subItem.name} {subItem.count !== undefined && subItem.count !== 0 && `(${subItem.count})`}
                </span>
              </NavDropdown.Item>
            ))}
          </div>
        </div>
      </NavDropdown>
    );
  };

  const renderNav = () => (
    <Nav as="ul"
     className=" border-top"
     >
      {getMenuItems().map((item) => (
        <NavItem as="li" key={`topnav-${item.name}`}>
          {item.submenu ? (
            <SubMenu item={item} />
          ) : (
            <Nav.Link as={NavLink} to={item.to} onClick={handleMenuItemClick}>
              {!isMobile && <span
               className="nav-link-icon"
               >{item.icon}</span>}
              <span 
              className="nav-link-title"
              >{item.name}</span>
            </Nav.Link>
          )}
        </NavItem>
      ))}
    </Nav>
  );

  return (
    <>
      {!isMobile && (
        <button
          className="btn btn-default btn-icon border-0 btn-sm shadow-none toggle-sidebar"
          onClick={() => dispatch(toggleSidebar())}
          style={{
            position: "fixed",
            top: "15px",
            left: isSidebarVisible ? "17rem" : "4.5rem", // Adjust based on sidebar state
            zIndex: 1030,
          }}
        >
          <IconMenu2 size={24} />
        </button>
      )}
      {/* {(isSidebarVisible || isMobile) && ( */}
      <Navbar
        as={"aside"}
        collapseOnSelect
        expand="lg"
        className={`navbar-vertical sticky-top ${isSidebarVisible || isMobile ? "" : "hide"}`} // Hide sidebar when not visible
        expanded={isExpanded} // Control expanded state
        onToggle={(expanded) => setIsExpanded(expanded)} // Update state on toggle
      >
        <Container fluid>
          <Navbar.Brand as={"h1"} className="justify-content-md-start ps-md-3">
            <Link to={"/"}>
              <div className="d-flex align-items-center">
                <img src={logo} className="navbar-brand-image" alt="Logo" />
                <div className="ms-2 logo-text">
                  <div className={`${!isMobile ? "fs-4" : "fs-18"} my-1 text-primary fw-medium`}>
                    {authSelector.role.slug == "super-admin" ||
                      authSelector.role.slug == "director-general" ||
                      authSelector.role.slug == "additional-member-staff" ||
                      authSelector.role.slug == "executive-director-establishment" ||
                      authSelector.role.slug == "establishment-director-i" ||
                      authSelector.role.slug == "establishment-director-ii" ||
                      authSelector.role.slug == "deputy-director" ||
                      authSelector.role.slug == "control-admin" ? (
                      "RRCB"
                    ) : (
                      "Railway Recruitment Board"
                    )}
                  </div>

                  {!isMobile && (
                    <div>
                      {authSelector.role.slug == "super-admin" ||
                        authSelector.role.slug == "director-general" ||
                        authSelector.role.slug == "additional-member-staff" ||
                        authSelector.role.slug == "executive-director-establishment" ||
                        authSelector.role.slug == "establishment-director-i" ||
                        authSelector.role.slug == "establishment-director-ii" ||
                        authSelector.role.slug == "deputy-director" ||
                        authSelector.role.slug == "control-admin" ? (
                        <div className="fs-5 fw-normal">Ministry of Railways</div>
                      ) : (
                        <div className="fs-5 fw-normal">{authSelector.rrb?.city}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Link>
          </Navbar.Brand>
          {/* <Navbar.Toggle className="ms-auto me-2" /> */}
          <Navbar.Toggle className={`ms-auto me-2 ${authSelector.role.slug === ROLES.CENTER_COORDINATOR ? "d-none" : ""}`}>
            {isExpanded ? (
              <IconX size={24} /> // X icon when expanded
            ) : (
              <IconMenu2 size={24} /> // Menu icon when collapsed
            )}
          </Navbar.Toggle>
          <Nav as={"div"} className="flex-row d-lg-none">
            <HeaderDropdown isMobile />
          </Nav>
          <Navbar.Collapse className={`${authSelector.role.slug === ROLES.CENTER_COORDINATOR ? "d-none" : ""}`}>
            {renderNav()}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* )} */}
    </>
  );
};

export default Sidebar;
