import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AnimatedComponent from "../../components/Animation/AnimatedComponent";
import Loader from "../../components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardDetails, getDashboardDetailsById } from "../../api/DashboardAPI";
import { ROLES } from "../../common/constants";
import { PageBody } from "../../components/SiteWrapper";
import ControlAdminCount from "./ControlAdminCount";
import ControlAdminDashboard from "./ControlAdminDashboard";
import CountWidget from "./CountWidget";
import InProgressExam from "./InProgressExam";
import SuperAdminDashboard from "./SuperAdminDashboard";
import { UpcomingExam } from "./UpcomingExam";

const Dashboard = ({ rrb_id = null, name = "Dashboard" }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const dashboardName = searchParams.get("name");

  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  const isSuperadmin = authSelector?.role?.slug === ROLES.SUPERADMIN;
  const isControladmin = authSelector?.role?.slug === ROLES.CONTROL_ADMIN;
  const navigate = useNavigate();

  const [dashboard, setDashboard] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboardData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = id
      ? await getDashboardDetailsById(id)
      : await getDashboardDetails();
      // const res = await getDashboardDetails();
      setDashboard(res.data);
      console.log("res.data.runningExams.examDayReportProgress", res.data.runningExams[0].examDayReportProgress);

    } catch (error) {
      console.log("🚀 ~ fetchDashboardData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authSelector.role.slug === ROLES.CENTER_COORDINATOR) {
      navigate("/exams");
    } else {
      fetchDashboardData();
    }
  }, []);

  return (
    <AnimatedComponent as={PageBody}>
      <div className="page-header mt-0">
        <h2 className="page-title mb-2">{dashboardName || "Dashboard"}</h2>
      </div>
      {isLoading && <Loader />}
      <Row className="g-3">
        {isControladmin ? (
          <ControlAdminCount data={dashboard}/>
        ) : (
          <CountWidget data={dashboard} isSuperadmin={isSuperadmin} />
        )}

        {isSuperadmin ? (
          <SuperAdminDashboard data={dashboard} setDashboard={setDashboard} />
        ) : isControladmin ? (
          <ControlAdminDashboard />
        ) : (
          <Fragment>
            <InProgressExam data={dashboard?.runningExams} rrb_id={rrb_id} setDashboard={setDashboard} />
            <UpcomingExam data={dashboard?.upcomingExams} />
            {/* {(!isSuperadmin && dashboard?.runningExams?.length === 0 && dashboard?.upcomingExams?.length === 0) && <Exams isDashboard={true} />} */}
          </Fragment>
        )}
      </Row>
    </AnimatedComponent>
  );
};

export default Dashboard;
