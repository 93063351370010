import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as UsersAPI from "../../api/UsersAPI";
import { PAGE_LENGTH } from "../../common/constants";
import { handleApiError } from "../../common/errorHandler";
import ContactBox from "../../components/Charts/ContactsBox";
import DataTable from "../../components/DataTable";
import { UserNameCell } from "../../components/DataTable/TableCell";

const AdminContacts = ({ examDay, exam,rrb_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [cuurentCenter, setCurrentCenter] = useState(0);
  const [currentCCnum, setCurrentCCnum] = useState(0);


  const [totalRecords, setTotalRecords] = useState(0);
  const [divisionCount, setDivisionCount] = useState(0);
  const [RcArcCount, setRcArcCount] = useState(0);

  const [filter, setFilter] = useState({
    _page: 1,
    _limit: 10,
    q: "",
    _sort: "id",
    _order: "asc",
    roleFilter: null,
  });
  const initialState = {
    sortBy: [{ id: "id", desc: false }],
    pageSize: filter._limit,
    pageIndex: 0,
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "text-nowrap w-auto",
        disableSortBy: true,
        Cell: UserNameCell,
        // width:"250",
      },
      {
        Header: "Designation",
        accessor: "designation",
        className: "text-nowrap",
        disableSortBy: true,
        width: "200",
        Cell:({value})=>value ? value : "N/A"
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        className: "text-nowrap",
        disableSortBy: true,
        width: "200",
        Cell: ({ row }) => (
          <a target="_blank" href={`https://wa.me/${row.original.mobile}`} rel="noreferrer" className="text-dark text-underline">
            {row.original.mobile}
          </a>
        ),
      },
      {
        Header: "Role",
        accessor: "Role.name",
        className: "text-nowrap",
        disableSortBy: true,
        width: "200",
      },
    ],
    []
  );
  
  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    let examDate;
    let examName;
console.log("exam",exam)
    if(examDay === undefined && exam === undefined){
        examDate= ""
        examName= ""
    }else if(exam !== undefined && examDay === undefined ){
        examName = exam
    }

    else if(exam !== undefined && examDay !== undefined ){
        examDate= examDay
        examName = exam
    }
    else if(exam !== undefined && examDay === null ){
        examName = exam
    }

    try {
      await UsersAPI.getUsers(filter, examDate, examName,rrb_id).then(({ data }) => {
        if (data?.users) {
          setTotalRecords(data.totalUser);
          setCurrentCCnum(data.coordinatorCount);
          setRows(data?.users);
          setDivisionCount(data?.divisionCount);
          setCurrentCenter(data?.centerCount)
          setRcArcCount(data?.RcArcCount)
        }
      });
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, [filter, examDay, exam]);
  useEffect(() => {
    const getData = setTimeout(() => {
      fetchUser();
    }, 100);
    return () => clearTimeout(getData);
  }, [filter, examDay, exam]);

  return (
    <Card className="admin-contacts-container">
      <Card.Header className="bg-light-rrb">
        <h3 className="fs-16 mb-0 fw-medium">RRB Contacts</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="row-cards align-items-center ">
          <Col md={3} className="p-3 ps-4">
            <ContactBox
              isSuperAdmin={false}
              data={{ division: divisionCount, rcarc: RcArcCount, centers: cuurentCenter, cc: currentCCnum }}
            />
          </Col>
          <Col className="border-start ps-0">
            <DataTable
              // header={<PageHeaderActions title="Users" actionText="Add" onClick={AddUser} />}
              columns={columns}
              data={rows}
              initialState={initialState}
              setFilter={setFilter}
              totalRecords={totalRecords}
              tableHooks={() => {}}
              defaultPageLength={PAGE_LENGTH}
              isLoading={isLoading}
              manual={true}
              align="table-vcenter"
              cardClass="border-0 "
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AdminContacts;
