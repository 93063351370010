import React from 'react'


export default function ControlAdminDashboard() {
    return (
        <>
           
        </>

    )
}
